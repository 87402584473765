body {
  padding: 40px;
  font-family: Sans-Serif;
}

.form {
  display: flex;
  flex-direction: column;
}

.label-class {
  display: flex;
  flex-direction: column;
  background-color: #F0F0F0;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 5%;
}

.input-field {
  width: 100%;
  height: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid black;
  margin-top: 5px;
}

.label-text {
  font-size: 30x;
  margin-bottom: 20px;
}



.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-button {
  margin-inline: 7.5%;
  width: 75px;
}
.startButton {
  margin: 10%;
  display: flex;
  justify-content: center;

}

.area-secondary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label-text-half {
  width: 40%;
}

.input-field2 {
  
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden; /* Hide the scrollbar */
    resize: none; /* Disable manual resizing */
    margin-top: 5px;

}

.page-counter {
  text-align: center;
  color: grey;
  font-size: 12px;
}

.main {
  display: flex;
  flex-direction: column;
}

#logo {
  width: 30%;
  height: 30%;
  align-self: center;
  margin-bottom: 30px
}

.center-text {
  text-align: center;
}